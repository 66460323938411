<template>
    <div id="OrderCenter">
      <el-card>
  
        <AdminTopOper placeholder="请输入订单号">
          <template #defaut>
  
  
            <el-select v-model="pageData.where.status" placeholder="选择订单状态" @change="change">
              <el-option v-for="orderStatus in initData.orderStatus" :key="orderStatus.status" :label="orderStatus.name"
                :value="orderStatus.status" />
            </el-select>
  
            <el-button @click="click_exportAddress()">导出地址excel</el-button>
            <el-button @click="click_AllorderFile()">批量下载文件</el-button>
          </template>
        </AdminTopOper>
  
        <v-contextmenu ref="contextmenu">
          <v-contextmenu-item @click="contextmenu_down()"> <el-icon class="el_icon">
              <Download />
            </el-icon>下载文件</v-contextmenu-item>
          <v-contextmenu-item @click="click_oepnFile()"> <el-icon class="el_icon">
              <Document />
            </el-icon>打开文件</v-contextmenu-item>
          <v-contextmenu-item @click="click_oepnFolder()"> <el-icon class="el_icon">
              <FolderOpened />
            </el-icon>打开文件夹</v-contextmenu-item>
          <v-contextmenu-item @click="click_printDo()"> <el-icon class="el_icon">
              <Printer />
            </el-icon>打印</v-contextmenu-item>
          <v-contextmenu-item @click="click_printDo(false, 'printDo_direct')"> <el-icon class="el_icon">
              <Printer />
            </el-icon>直接打印</v-contextmenu-item>
  
  
  
        </v-contextmenu>
  
  
        <div class="outBox">
          <div class="OrderOutBox">
  
            <div v-for="Order in pageData.rows" :key="Order.id" @click="clickOrderOne(Order)">
              <a href="javascript:void(0);">
                <div :class="{ OrderActive: OrderActive == Order.id, Order: true, smallFont: true }">
                  <span class="smallFont">{{ Order.number }} </span> <span class="smallFont">【{{ refererName(Order)
                  }}】</span>
                  <div style="display: flex;line-height: 35px;margin-bottom: 6px;">
                    <div>
                      {{ Order.Files.length }} 个文件， <span class="rmb">{{ Order.amount ? Math.round(Order.amount * 100) / 100
                        :
                        0 }}</span><span class="smallFont">元</span>
                    </div>
  
                    <div style="margin-left: 20px;">
                      <div v-if="Order.status == 10">待付款</div>
                      <div v-if="Order.status == 20 && Order.printing != 1">
                        <el-tag size="default" type="warning" effect="dark">待打印</el-tag>
                      </div>
                      <div v-if="Order.status == 30">
                        <el-tag type="success" effect="dark">待收货</el-tag>
                      </div>
                      <div v-if="Order.status == 50">
                        <el-tag type="success">订单完成</el-tag>
                      </div>
                      <div v-if="Order.printing == 1 && Order.status == 20">
                        <el-tag type="warning">开始打印</el-tag>
                      </div>
                    </div>
                  </div>
  
                  <div>
                    <div>ID：{{ Order.userId }}，{{ Order.username }}，{{ JSON.parse(Order.addressJSON).receiver }}</div>
                  </div>
  
                  <div>
                    <div> {{ Order.createdAt }} </div>
                  </div>
  
  
  
  
                </div>
              </a>
  
            </div>
  
  
          </div>
  
          <div class="orderDetail">
  
            <div class="buttonBox">
              <el-button @click="click_printFileAll_2()"> 直接打印（all）</el-button>
              <el-button @click="click_downFileAll()"> 下载文件（all）</el-button>
              <el-button @click="click_printFileAll()"> 打印文件（all）</el-button>
              <el-button @click="click_sendRawData()"> 输出到打印机控制（all）</el-button>
            </div>
  
            <div class="buttonBox tips">
              预计用纸：{{ getTips().needPaperCount }} 张 。 系统提示：{{ getTips().tips }}
            </div>
  
            <div class="fileOutBox">
  
              <div v-for="File, index in Order.Files" :key="File.id" @click="click_fileOutBox(File)">
                <div class="fileBox" v-loading="File.actvieMessage" :element-loading-text="File.actvieMessage"
                  v-contextmenu:contextmenu @contextmenu.prevent.stop="fileBox_rightClick(File)">
  
  
  
                  <div class="filename">
                    <!-- <img src="@/assets/img/pdf.png" alt="" v-if="File.type == 'pdf'">
                    <img src="@/assets/img/word.png" alt="" v-if="File.type == 'word'">
                    <img src="@/assets/img/ppt.png" alt="" v-if="File.type == 'ppt'">
                    <img src="@/assets/img/txt.png" alt="" v-if="File.type == 'img'"> -->
                    <div>
                      <div class="filenameText">
                        {{ File.oldfilename }}
                      </div>
                      <div class="filenameTextBottom">
                        （ {{ index + 1 }} ）
                        <!-- 【{{ 2.6 }} M】 【PCS】  -->
                        【 {{ File.pageCount }} 页 】 【 {{ File.totalPrice }} 元 】
                      </div>
                    </div>
                  </div>
  
                  <div class="printParams">
  
                    <div>
                      <text :class='{ double: File.double == "双面", strong: File.double != "双面" }'> {{ File.double }}</text>
                      <text class='fenge'> 丨 </text>
                    </div>
                    <div>
                      <div :class="{ colorFont: File.style != '黑白' }">{{ File.style }} <text class='fenge'> 丨 </text></div>
                    </div>
                    <div :class="{ strong: File.size != 'A4' }"> {{ File.size }} </div>
                    <text class='fenge'> 丨 </text>
                    <div :class="{ strong: File.material != '70g复印纸' }"> {{ File.material }} </div>
                    <text class='fenge'> 丨 </text>
                    <div :class="{ strong: File.binding != '不装订' && File.binding != '订书针' }">
                      {{ File.binding }} <text class='fenge'> 丨 </text>
                    </div>
                    <div v-if="File.scale != '每版打印1页'" class="strong"> {{ File.scale }} <text class='fenge'> 丨 </text>
                    </div>
  
                    <div v-if="File.binding == '皮纹纸胶装'">
                      <div> {{ File.coverColor }} <text class='fenge'> 丨 </text></div>
                    </div>
                    <div v-if="File.binding == '皮纹纸胶装'">
                      <div> {{ File.coverContent }} <text class='fenge'> 丨 </text> </div>
                    </div>
                    <div v-if="File.binding == '皮纹纸胶装' && File.coverContent == '文字封面'">
                      <div> {{ File.coverText }} <text class='fenge'> 丨 </text> </div>
                    </div>
                    <div :class="{ strong: File.count != 1 }">
                      【 <text :class='{ PageCount: File.count != 1, PageCount2: true }'> {{ File.count }} </text> 份】 <text
                        class='fenge'> </text>
                    </div>
                  </div>
  
  
                  <div class="filenameTextBottom">
                    【 {{ (Math.round(File.fileSize / 100000) / 10) }} m 】 【 {{ File.uploadMethod }} 】 <el-tag
                      v-if="File.filePath" size="default">已下载</el-tag>
                    <el-tag v-if="File.file" size="default" type="success" style="margin-left: 6px;">数据已生成</el-tag>
                    <el-tag v-if="false" size="default" type="success" style="margin-left: 6px;">打印完成</el-tag>
  
                  </div>
  
                  <div v-if="false">
                    {{ File.filePath }}
                  </div>
  
                  <!-- <div class="activeBox" v-if="File.actvieMessage" >
                    <div> {{ File.actvieMessage }} </div>
                  </div> -->
                </div>
  
              </div>
            </div>
  
          </div>
        </div>
  
  
  
        <div>
          <el-button @click="clickAll('MarkAsPrinted')">未发货订单设置为开始打印</el-button>
  
          <el-button @click="clickAll('MarkAsPrint')">未发货订单设置为未打印</el-button>
        </div>
  
  
        <AdminPagination :showEdit="false"> </AdminPagination>
  
  
        <!-- <el-dialog v-model="PrintDialogTableVisible" :title="`打印数据输出到打印机控制 作业ID: ${Order.id} | 订单金额：${Order.amount} 元 `"> -->
        <el-dialog v-model="PrintDialogTableVisible" title='打印数据输出到打印机控制' :draggable="true" width="63%">
          <div class="PrintDialog">
  
            <el-table :data="printFiles">
              <el-table-column label="序号" width="60">
                <template #default="scope"> {{ scope.$index + 1 }} </template>
              </el-table-column>
              <el-table-column label="文件名" prop="oldfilename" width="200" show-overflow-tooltip />
              <!-- <el-table-column label="页数" width="60">
                <template #default="scope"> {{ scope.row.count }} </template>
              </el-table-column> -->
              <el-table-column label="页数" width="60">
                <template #default="scope"> {{ scope.row.file.NumberOfPages }} </template>
              </el-table-column>
              <el-table-column label="份数" width="60">
                <template #default="scope"> {{ scope.row.file.dmCopies }} </template>
              </el-table-column>
              <el-table-column label="大小" width="60">
                <template #default="scope"> {{
                  scope.row.file.dmPaperSize == 9 ? 'A4' : scope.row.file.dmPaperSize == 8 ? 'A3' : 'B5' }} </template>
              </el-table-column>
              <el-table-column label="单双" width="60">
                <template #default="scope"> {{ scope.row.file.dmDuplex != 1 ? '双面' : '单面' }} </template>
              </el-table-column>
              <el-table-column label="颜色" width="60">
                <template #default="scope">
                  <div v-if="scope.row.file.dmColor == 2 && scope.row.style != '黑白'"> 彩色</div>
                  <div v-if="scope.row.file.dmColor != 2 || scope.row.style == '黑白'"> 黑白</div>
                </template>
              </el-table-column>
              <el-table-column label="单双" width="60">
  
                <template #default="scope"> {{ scope.row.file.dmOrientation }} {{ scope.row.file.dmOrientation == 3 ? '横向' :
                  '竖向' }} </template>
              </el-table-column>
  
  
              <el-table-column label="打印机" width="180" show-overflow-tooltip>
                <template #default="scope"> {{ scope.row.file.pPrinterName }} </template>
              </el-table-column>
              <el-table-column label="状态" width="105">
                <template #default="scope">
                  <el-tag class="ml-2" type="success" v-if="scope.row.sendPrintData">成功</el-tag>
                  <el-tag class="ml-2" type="info" v-if="!scope.row.sendPrintData">待发送</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="200">
                <template #default="scope">
                  <el-button @click="click_sendPrintFile(scope.row)">发送</el-button>
                  <el-button @click="click_deletePrintFile(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
  
          <div class="PrintDialogBottom">
            <el-button @click="PrintDialogTableVisible = false">取消</el-button>
            <el-button @click="click_sendPrintFileAll()" type="primary">全部发送</el-button>
          </div>
        </el-dialog>
  
      </el-card>
  
    </div>
  </template>
    
  <script setup >
  import { onMounted, onUpdated, reactive, ref, watch } from 'vue'
  import Api from '@/network'
  import { request } from '@/network/request'
  import { useRouter } from 'vue-router'
  import OrderPrint from "../Order-print"
  import OrderExpand from "../Order-expand"
  import axios from 'axios'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import store from '@/store'
  import { ElNotification } from 'element-plus'
  import { sync } from 'glob'
  import { resolve } from 'path'
  
  const router = useRouter()
  const pageData = reactive(Api.adminPage('order'))
  pageData.count = 6
  pageData.limit = 6
  pageData.where.status = 20
  let OrderActive = ref(0)
  let Order = ref({})
  let printFiles = ref([])
  let PrintDialogTableVisible = ref(true)
  let duration = ref(1000 * 20)
  setTimeout(() => {
    PrintDialogTableVisible.value = false
  }, 10)
  let rightActiveFile = ref({})
  let storagePath = "C:\\prePrint2"
  onMounted(async () => {
    await pageData.getPage()
    OrderActive.value = pageData.rows[0].id
    Order.value = pageData.rows[0]
  })
  // watch(pageData.offset, (newValue, oldValue) => {
  //   for (let order of pageData.rows) {
  //     let index = 0
  //     for (let File of order.Files) {
  //       File.oldfilename = File.filename
  //       File.index = index
  //       index++
  //     }
  //   }
  // })
  
  
  const initData = ref(JSON.parse(localStorage.getItem('initData')))
  
  const clickAll = (type) => {
    Api.request({
      url: '/admin/order/adminDataHandle',
      method: 'post',
      data: {
        type: type
      }
    })
  }
  
  
  
  
  const refererName = (row) => {
    if (!row.referer) row.referer = ''
    for (let mini of store.state.initData.wxMiniProgram_list) {
      if (row.referer.indexOf(mini.appid) > 0) {
        return mini.name
      }
    }
    return "博立云印"
  }
  
  const clickOrderOne = (order) => {
    console.log(order)
    OrderActive.value = order.id
    Order.value = order
    console.log(Order.value.Files)
  
  }
  const fileBox_rightClick = (File) => {
    rightActiveFile.value = File
  }
  
  const click_downFileAll = async () => {
    let isPcS = false
    for (let file of Order.value.Files) {
      if (file.uploadMethod == 'pcs') { isPcS = true }
    }
    if (isPcS && !Order.value.up_pcs) { await update_psc() }
    let time = 0
    for (let File of Order.value.Files) {
      setTimeout(() => { contextmenu_down(File) }, time)
      time += File.fileSize / 1000 / 30
    }
    ElNotification({ title: 'Success', message: "全部文件下载完毕\n" + Order.value.number, type: 'success', duration: 30000 })
  }
  
  const click_printFileAll = async () => {
    let time = 0
    for (let File of Order.value.Files) {
      setTimeout(() => { click_printDo(File) }, time)
      time += 2000
    }
    ElNotification({ title: 'Success', message: "全部文件打印完毕\n" + Order.value.number, type: 'success', duration: 30000 })
  }
  const click_printFileAll_2 = async () => {
    for (let File of Order.value.Files) {
      if (File.printed) continue;
      await click_printDo(File, "printDo_direct")
    }
    ElNotification({ title: 'Success', message: "全部文件打印完毕\n" + Order.value.number, type: 'success', duration: 30000 })
  }
  const click_sendRawData = () => {
    let Files = JSON.parse(JSON.stringify(Order.value.Files))
    printFiles.value = Files.filter(i => Boolean(i.file))
    PrintDialogTableVisible.value = true
  }
  
  const click_fileOutBox = async (File) => {
    return
    let Path = File.filePath
    let res = await axios({
      url: 'http://127.0.0.1:12000',
      method: 'GET',
      params: {
        Path: escape(Path),
        //  deepDir: escape(JSON.stringify(deepDir)),
        operation: "openDocument",
      }
    })
  }
  const click_oepnFile = async (File) => {
    if (!File) File = rightActiveFile.value
    if (!File.filePath) {
      await contextmenu_down()
    }
    let Path = File.filePath
    let res = await axios({
      url: 'http://127.0.0.1:12000',
      method: 'GET',
      params: {
        Path: escape(Path),
        //  deepDir: escape(JSON.stringify(deepDir)),
        operation: "openDocument",
      }
    })
  }
  const click_oepnFolder = async () => {
    let res = await axios({
      url: 'http://127.0.0.1:12000',
      method: 'GET',
      params: {
        Path: escape(storagePath + "\\" + Order.value.number),
        //  deepDir: escape(JSON.stringify(deepDir)),
        operation: "openDocument",
      }
    })
  
  }
  
  const click_printDo = async (File, operation) => {
    if (!operation) operation = "printDo"
    if (!File) File = rightActiveFile.value
    if (!File.filePath) {
      await contextmenu_down()
    }
    File.actvieMessage = "正在生成打印数据"
    try {
      let PrintFile = getPrintFile(File)
      let res = await axios({
        url: 'http://127.0.0.1:12000',
        method: 'GET',
        params: {
          FileName: escape(File.filePath),
          Name: escape(File.filename),
          File: escape(JSON.stringify(PrintFile)),
          operation: operation,
        }
      })
      File.file = res.data.file
      File.actvieMessage = false
      File.printed = true
      console.log(File)
      if (File.file) click_sendRawData()
    } catch (error) {
      File.actvieMessage = false
      return ElNotification({ title: 'Error', message: " 打印错误 文件处理程序未启动", type: 'error' })
  
    }
  
  }
  const update_psc = async () => {
  
    let update_dlinkRes = await Api.request({
      url: '/update_dlink',
      method: 'POST',
      data: {
        file_list: Order.value.Files
      }
    })
    console.log(update_dlinkRes.list)
    for (let li of update_dlinkRes.list) {
      for (let file of Order.value.Files) {
        if (file.fs_id == li.fs_id) {
          file.url = li.dlink + `&access_token=${store.state.initData.pcs_accessToken}`
        }
      }
    }
    ElNotification({ title: 'Success', message: "更新网盘下载链接成功", type: 'success', })
    return Order.value.up_pcs = true
  }
  const contextmenu_down = async (File) => {
  
    let isPcS = false
    for (let file of Order.value.Files) {
      if (file.uploadMethod == 'pcs') { isPcS = true }
    }
    if (isPcS && !Order.value.up_pcs) { await update_psc() }
  
  
    if (!File) File = rightActiveFile.value
    File.actvieMessage = "正在下载文件"
    File.filename = `【${File.index + 1}】【${File.pageCount}页】${File.filename}`
    try {
      let downloadRes = await axios({
        url: 'http://127.0.0.1:12000',
        method: 'GET',
        params: {
          storagePath: escape(storagePath),
          orderNumber: escape(Order.value.number),
          fileJson: escape(JSON.stringify(File)),
          //  deepDir: escape(JSON.stringify(deepDir)),
          operation: "downloadFileOne",
  
        }
      })
      if (downloadRes.data != "ok") {
        File.actvieMessage = "下载失败"
        setTimeout(() => File.actvieMessage = false, 500)
        return ElNotification({
          // title: 'Success',
          title: 'Error',
          message: downloadRes.data,
          type: 'error',
        })
      }
      File.filePath = storagePath + "\\" + Order.value.number + "\\" + File.filename
      File.actvieMessage = "下载完毕"
      File.actvieMessage = false
      // pageData.rows[0].Files[0] = File
      return ElNotification({
        // title: 'Success',
        title: 'Success',
        message: File.filename + " 下载成功",
        type: 'success',
      })
      console.log(downloadRes)
    } catch (error) {
      File.actvieMessage = false
      return ElNotification({
        // title: 'Success',
        title: 'Error',
        message: " 下载错误 文件处理程序未启动",
        type: 'error',
      })
  
    }
  }
  const getTips = () => {
  
    let File_list = Order.value.Files
    if (!File_list) File_list = []
    let paperCount = 0
    let isSingle = false
    let isColor = false
    let colorCount = 0
    let isHighColor = false
    let needBinding = File_list.length
    let singleCount = 0
    let jiaozhuang = 0
    let tiequan = 0
    let ding = 0
    let qimading = 0
    let bindingTips = ''
    let is_scale = false
    let is_noAllPage = false
    let is_80g = false
    let is_A3 = false
    let is_B5 = false
    let is_count = false
    for (let file of File_list) {
      // 预计纸张 是否单面
      let scale = ''
      scale = file.scale.replace('每版打印', '')
      scale = scale.replace('页', '')
      scale = Number(scale)
      if (scale != 1) { is_scale = true }
      if (file.range != '全部') { is_noAllPage = true }
      let pageCount = file.pageCount / scale
  
      if (file.double == '双面') {
        if (pageCount % 2 != 0) pageCount = pageCount + 1
        paperCount = paperCount + (pageCount / 2) * file.count
      } else {
        paperCount = paperCount + pageCount * file.count
        isSingle = true
        singleCount++
      }
      if (file.style == '彩色') {
        isColor = true
        colorCount++
      }
      if (file.style == '激光彩色') { isHighColor = true }
      if (file.binding == '不装订') { needBinding = needBinding - 1 }
      if (file.binding == '皮纹纸胶装') { jiaozhuang = jiaozhuang + 1 }
      if (file.binding == '铁圈装') { tiequan = tiequan + 1 }
      if (file.binding == '钉书针') { ding = ding + 1 }
      if (file.qimading == '骑马订') { qimading = qimading + 1 }
      if (file.material == '80g复印纸') { is_80g = true }
      if (file.size == 'A3') { is_A3 = true }
      if (file.size == 'B5') { is_B5 = true }
      if (file.count > 1) { is_count = true }
    }
    paperCount = Math.round(paperCount)
    let tips = `${isSingle ? '有单面(' + singleCount + ')，' : ''}${is_80g ? '有80g' : ''}${isColor ? '有彩色(' + colorCount + ')，' : ''}${isHighColor ? '有激光彩色，' : ''}${needBinding ? '需要装订:' + needBinding + '本,' : ''}${is_scale ? '有多页合一，' : ''}${is_noAllPage ? '有选页码打印，' : ''}${is_A3 ? '有A3，' : ''}${is_B5 ? '有B5，' : ''}${is_count ? '有2份以上，' : ''}`
    tips = tips + `。${jiaozhuang ? '胶装:' + jiaozhuang + '本,' : ''}${tiequan ? '铁圈装:' + tiequan + '本,' : ''}${qimading ? '骑马订:' + qimading + '本,' : ''}`
    return { tips, needPaperCount: paperCount }
  
  }
  const change = () => {
    pageData.getPage()
  }
  const getPrintFile = (File) => {
  
    let black_printer1 = { printerName: '黑白1号', ip: '192.168.1.100' }
    let black_printer2 = { printerName: '黑白2号', ip: '192.168.31.210' }
    let black_printer3 = { printerName: '黑白3号', ip: '192.168.31.175' }
    let black_printer4 = { printerName: '黑白4号', ip: '192.168.31.175' }
    let black_printer5 = { printerName: '黑白2号(左侧双钉)', ip: '192.168.31.210' }
    let black_printer6 = { printerName: '黑白2号(横向角钉)', ip: '192.168.31.210' }
    let color_printer1 = { printerName: '彩色1号', ip: '192.168.31.186' }
    let color_printer2 = { printerName: '彩色2号', ip: '192.168.31.175' }
  
    // blakc2 = "Canon iR-ADV 6255/6265 UFR II"
    // color1 = "Canon iR-ADV 6555/6565 UFR II"
    let printer = black_printer2
    let printer2 = black_printer2
    if (File.binding == "订书针" && File.style == "黑白" ) {
      printer = black_printer5
      printer2 = black_printer6
     }
    // if (File.binding == "不装订" && File.pageCount >= 35 && File.pageCount < 200) {
    //     printerBlack = "黑白2号(左侧装订)"
    //   //  printerBlack = "黑白1号"
    //   }
    if (File.style == "彩色") { printer = color_printer1; printer2 = color_printer1 }
    if (File.style == "激光彩色") { printer = color_printer1; printer2 = color_printer1 }
    let dmPaperSize = 9
    if (File.size == 'A3') dmPaperSize = 8
    if (File.size == 'B5') dmPaperSize = 13
    let objFile = {
      ...File,
      url: File.url,
      Extension: File.Extension,
      dmDuplex: File.double == "单面" ? 1 : 2,
      printer: printer.printerName,
      printer2: printer2.printerName,
      dmPaperSize: dmPaperSize,
      dmCopies: File.count,
      printerPort: printer.ip,
    }
    File.printerPort = printer.ip
    File.printer = printer.printerName
    return objFile
  }
  const click_deletePrintFile = (File) => {
    for (let file of Order.value.Files) {
      if (file.id == File.id) {
        file.file = false
      }
    }
    click_sendRawData()
  }
  const click_sendPrintFile = async (file) => {
    let res = await click_sendToIPPort(file)
    if (res == 'ok') { file.sendPrintData = true }
  }
  
  const click_sendPrintFileAll = async () => {
    for (let File of printFiles.value) {
      await click_sendPrintFile(File)
      await sleep(3500)
    }
  }
  const sleep = (time) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve('ok')
      }, time)
    })
  }
  const click_sendToIPPort = async (File) => {
    File.rawPath = File.file.rawPath
    let downloadRes = await axios({
      url: 'http://127.0.0.1:12000',
      method: 'GET',
      params: {
        fileJson: escape(JSON.stringify(File)),
        operation: "sendToIPPort",
      }
    })
    if (downloadRes.data == 'ok') {
      ElNotification({ title: 'Success', message: "发送到打印机成功", type: 'success' })
      return "ok"
    }
    return ElNotification({ title: 'Error', message: downloadRes.data, type: 'error', duration: duration.value })
  
  }
  </script>
    
    
  
  <style lang="less">
  .outBox {
    display: flex;
  
    //height: 600px;
    //justify-content: space-between;
    .orderDetail {
      background-color: #f5f5f5;
      width: 800px;
      margin: 10px;
      border-radius: 4px;
      height: 600px;
      overflow: auto;
      padding: 15px;
    }
  }
  
  .OrderOutBox {
    width: 750px;
    display: flex;
    flex-wrap: wrap;
    height: 630px;
    overflow: auto;
  
    .Order {
      background-color: rgba(247, 247, 247);
      width: 300px;
      height: 150px;
      margin: 10px;
      border-radius: 8px;
      padding: 20px;
      color: #444;
      font-size: 15px;
      line-height: 30px;
    }
  
  }
  
  .rmb {
    color: #333;
    font-size: 17px;
    font-weight: 700;
    margin-right: 6px;
  }
  
  .smallFont {
    font-size: 13px;
    color: #666;
  }
  
  .OrderActive {
    background-color: #111 !important;
    color: white !important;
    font-weight: 700;
  
    .rmb {
      color: white !important;
      font-size: 17px;
      font-weight: 700;
      margin-right: 6px;
    }
  
    .smallFont {
      font-size: 13px;
      color: white !important;
    }
  }
  
  .fileOutBox {
    display: flex;
    flex-wrap: wrap;
  
    .fileBox {
      width: 343px;
      min-height: 105px;
      background-color: white;
      margin: 10px;
      padding: 15px;
      border-radius: 8px;
      font-size: 13px;
  
      .filename {
        display: flex;
        line-height: 20px;
        height: 42px;
        font-size: 13px;
  
        .filenameText {
          width: 280px;
          height: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
  
        .filenameTextBottom {
          margin-top: 7px;
        }
  
        img {
          margin-right: 5px;
          width: 42px;
          height: 42px;
        }
      }
    }
  
    .fileBox:hover {
      background-color: rgb(221, 221, 221);
    }
  
    .printParams {
      display: flex;
      font-size: 12px;
      margin-top: 12px;
      flex-wrap: wrap;
      line-height: 25px;
      margin-bottom: 10px;
    }
  
    .fenge {
      color: #d3d3d3;
    }
  
    .strong {
      font-size: 15px !important;
      font-weight: 700;
      color: #5a5a5a;
    }
  
  }
  
  .buttonBox {
    background-color: white;
    padding: 20px;
    margin: 12px;
    border-radius: 8px;
    width: 720px;
  }
  
  // 右键菜单属性
  .v-contextmenu-item {
    padding: 10px 14px !important;
    line-height: 1;
    color: #333;
    cursor: pointer;
    margin: 7px;
    border-radius: 4px;
  
  }
  
  .v-contextmenu-item:hover {
    padding: 10px 14px !important;
    line-height: 1;
    background-color: rgb(230, 230, 230) !important;
    cursor: pointer;
  }
  
  .el_icon {
    font-size: 16px;
    vertical-align: -13%;
    margin-right: 6px;
  }
  
  .tips {
    font-size: 14px;
  }
  
  .colorFont {
    color: #07C160;
    font-weight: 700;
  }
  
  .PrintDialog {
    background-color: rgb(207, 207, 207);
    border-radius: 6px;
    padding: 8px;
  
    .el-table {
      height: 460px;
    }
  
  }
  
  .PrintDialogBottom {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  </style>
    